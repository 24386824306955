/**
 * Author: Jason Farrell
 * Author URI: http://useallfive.com/
 *
 * Description: Checks if a DOM element is truly visible.
 * Package URL: https://github.com/UseAllFive/true-visibility
 */
Element.prototype.isVisible = function () {
    'use strict';

    /**
     * Checks if a DOM element is visible. Takes into
     * consideration its parents and overflow.
     *
     * @param el      the DOM element to check if is visible
     * @param  top       Top corner position number
     * @param  right     Right corner position number
     * @param  bottom    Bottom corner position number
     * @param  left      Left corner position number
     * @param  width     Element width number
     * @param  height    Element height number
     *
     * @return bool
     */
    function _isVisible(el, top, right, bottom, left, width, height) {
        var parent = el.parentNode,
            VISIBLE_PADDING = 2;

        if (! _elementInDocument(el)) {
            return false;
        }

        // Return true for document node
        if (parent.nodeType === 9) {
            return true;
        }

        // Return false if our element is invisible
        if (
            _getStyle(el, 'opacity') !== '1' ||
            _getStyle(el, 'display') === 'none' ||
            _getStyle(el, 'visibility') === 'hidden'
        ) {
            return false;
        }

        if (
            typeof top === 'undefined' ||
            typeof right === 'undefined' ||
            typeof bottom === 'undefined' ||
            typeof left === 'undefined' ||
            typeof width === 'undefined' ||
            typeof height === 'undefined'
        ) {
            top = el.offsetTop;
            left = el.offsetLeft;
            bottom = top + el.offsetHeight;
            right = left + el.offsetWidth;
            width = el.offsetWidth;
            height = el.offsetHeight;
        }
        
        // If we have a parent, let's continue:
        if (parent) {
            // Check if the parent can hide its children.
            if (_getStyle(parent, 'overflow') === 'hidden' || _getStyle(parent, 'overflow') === 'scroll') {
                // Only check if the offset is different for the parent
                if (
                    left + VISIBLE_PADDING > parent.offsetWidth + parent.scrollLeft ||
                    left + width - VISIBLE_PADDING < parent.scrollLeft ||
                    top + VISIBLE_PADDING > parent.offsetHeight + parent.scrollTop ||
                    top + height - VISIBLE_PADDING < parent.scrollTop
                ) {
                    // Our target element is out of bounds:
                    return false;
                }
            }
            
            // Add the offset parent's left/top coords to our element's offset:
            if (el.offsetParent === parent) {
                left += parent.offsetLeft;
                top += parent.offsetTop;
            }
            
            // Let's recursively check upwards:
            return _isVisible(parent, top, right, bottom, left, width, height);
        }
        
        return true;
    }

    // Cross browser method to get style properties:
    function _getStyle(el, property) {
        if (window.getComputedStyle) {
            return document.defaultView.getComputedStyle(el, null)[property];
        }
        
        if (el.currentStyle) {
            return el.currentStyle[property];
        }
    }

    function _elementInDocument(element) {
        while ((element = element.parentNode)) {
            if (element == document) {
                return true;
            }
        }
        
        return false;
    }

    return _isVisible(this);
};
