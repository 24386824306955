(function (window, $, console, app) {
    var Handlebars = false;
    var repaint;
    
    if (window.Handlebars) {
        Handlebars = window.Handlebars;
    }
    
    function Modal() {
        var _this = this;
        var tpl = $('#tpl-modal').html();
        var template;
        var current = null;
        
        if (Handlebars) {
            template = Handlebars.compile(tpl);
        } else {
            template = function (context) {
                var rendered = tpl.replace(/{{\s*([A-z0-9$_-]+)\s*}}/g, function (match, $1) {
                    if (context.hasOwnProperty($1)) {
                        return context[$1];
                    }
                    
                    return $1;
                });
                
                return rendered;
            };
        }
        
        this.bind = function () {
            $(window.document).keyup(function(e) {
                if (e.keyCode == 27) {
                    _this.hide();
                }
            });
        };
        
        this.show = function (overlayId, content, data) {
            var $modal;
            
            if (current == content) {
                $modal = $('[data-md-inserted="true"]');
                
                $('html').addClass('@modal-open');
                $modal.addClass($modal.data('md-show'));
                
                return;
            }
            
            var $overlay = $('#' + overlayId);
            var tplData = {
                content: $('#' + content).html()
            };
            var show = data.mdShow;
            
            delete data.mdShow;
            
            $.each(data, function (key, value) {
                if (key.match(/^md([A-Z])/)) {
                    key = key.replace(/^md([A-Z])/, function (match, $1) {
                        return $1.toLowerCase();
                    });
                    
                    tplData[key] = value;
                }
            });
            
            console.log($overlay, tplData, show);
            
            $('html').addClass('@modal-open');
            
            $('[data-md-inserted="true"]').remove();
            
            $modal = $(template(tplData)).attr({
                'data-md-state': 'open',
                'data-md-inserted': 'true',
                'data-md-show': show
            }).insertBefore($overlay);
            
            repaint = $modal[0].offsetWidth;
            $modal.addClass(show).data('$overlay', $overlay);
            
            $overlay.off('click', null, _this.hide).click(_this.hide);
        };
        
        this.hide = function () {
            var $modal = $('[data-md-state="open"]');
            
            $modal.attr('data-md-state', 'closed').removeClass($modal.data('md-show'));
            $('html').removeClass('@modal-open');
        };
    }
    
    app.registerModule('Modal', new Modal());
})(window, jQuery, Console, app);
