(function (window) {
    var Date = window.Date;
    var setTimeout = window.setTimeout;
    var clearTimeout = window.clearTimeout;
    
    function Timer(delay, callback) {
        var timer, start, remaining = delay;
        
        this.clear = function () {
            clearTimeout(timer);
            remaining = delay;
            
            return this;
        };
        
        this.pause = function () {
            clearTimeout(timer);
            remaining -= new Date() - start;
            
            return this;
        };
        
        this.play = function () {
            this.clear();
            
            start = new Date();
            timer = setTimeout(callback, remaining);
            
            return this;
        };
    }
    
    function RecurringTimer(interval, callback) {
        var timer,
            start,
            _this = this,
            remaining = interval;
        
        this.clear = function () {
            clearTimeout(timer);
            remaining = interval;
            
            return this;
        };
        
        this.pause = function () {
            clearTimeout(timer);
            remaining -= new Date() - start;
            
            return this;
        };
        
        this.play = function () {
            clearTimeout(timer);
            
            start = new Date();
            timer = setTimeout(function () {
                remaining = interval;
                
                callback();
                _this.play();
            }, remaining);
            
            return this;
        };
    }
    
    function delay(interval, callback) {
        return setTimeout(callback, interval);
    }
    
    window.Timer = Timer;
    window.RecurringTimer = RecurringTimer;
    window.delay = delay;
})(window);
