(function (window, $, Argument, app) {
    var Array = window.Array;
    var console = window.Console;
    var actions = {};
    
    function ActionResolver() {
        var _this = this;
        
        var getValue = function ($el) {
            var value;
            
            if ($el.is('input, textarea, select')) {
                var $group = $([]);
                
                if ($el.is('[type="checkbox"]') || $el.is('[type="radio"]')) {
                    var nameAttr = $el.attr('name');
                    
                    $group = app.$body.find('input[name="' + nameAttr + '"]');
                }
                
                if ($el.is('[type="checkbox"]')) {
                    value = [];
                    
                    $group.filter(':checked').each(function () {
                        value.push($(this).val());
                    });
                } else if ($el.is('[type="radio"]')) {
                    value = $group.filter(':checked').val();
                } else {
                    value = $el.val();
                }
            }
            
            if (! value && ($el.attr('href') || $el.attr('xlink:href'))) {
                var href = $el.attr('href') || $el.attr('xlink:href') || '';
                
                value = href.replace(/^#/, '');
            }
            
            if (! value) {
                value = $el.data('value') || $el.data('val') || '';
            }
            
            return value;
        };
        
        var getRel = function ($el) {
            var rel = $el.attr('rel');
            
            if (! rel) {
                rel = $el.data('rel');
            }
            
            if (typeof rel === 'string') {
                return rel.replace(/^#/, '');
            }
            
            return rel;
        };
        
        var execAction = function (action, params) {
            if (actions[action]) {
                return actions[action].apply(app);
            }
            
            var matches = action.match(/([A-Z][A-z0-9_-]*)\.([a-z][A-z0-9_-]*)/);
            
            if (! matches) {
                throw new app.Exception('InvalidActionException', 'The action "' + action + '" is not a valid action string.');
            }
            
            var Module = app[matches[1]];
            
            if (typeof Module !== 'object') {
                throw new app.Exception('InvalidActionException', 'The Module "' + matches[1] + '" does not exist within the ' + app.NAME + ' Application.');
            }
            
            var method = Module[matches[2]];
            
            if (typeof method !== 'function') {
                throw new app.Exception('InvalidActionException', 'The method "' + matches[2] + '" does not exist within the Module "' + matches[1] + '".');
            }
            
            return method.apply(Module, params);
        };
        
        var resolveAction = function (action, params, $el, event) {
            var result;
            
            params = Argument.get(params, []);
            
            if (typeof $el !== 'undefined') {
                params.push($el);
            }
            
            if (typeof event !== 'undefined') {
                params.push(event);
            }
            
            try {
                result = execAction(action, params);
            } catch (e) {
                if (! (e instanceof app.Exception)) {
                    throw e;
                }
                
                console.error(e.name + ': ' + e.message);
            }
            
            return typeof result === 'undefined' ? true : result;
        };
        
        this.registerAction = function (name, action) {
            actions[name] = action;
        };
        
        this.exec = function () {
            var params = Array.prototype.slice.call(arguments);
            var name = params.shift();
            
            return resolveAction(name, params);
        };
        
        this.bindActions = function () {
            app.$body.on('click', 'a[data-action]:not([data-allow-default]), a[data-focus]:not([data-allow-default]), a[data-blur]:not([data-allow-default])', function (event) {
                event.preventDefault();
            });
            
            var bindEvent = function (type, event) {
                var $this = $(this);
                var params = [];
                var value = getValue($this);
                var rel = getRel($this);
                var data = $.extend({}, $this.data());
                var name = data[type];
                var result;
                
                if (! name && type === 'click') {
                    name = data.action;
                }
                
                if (rel) {
                    params.push(rel);
                }
                
                if (value || $this.is('input, textarea, select')) {
                    params.push(value);
                }
                
                delete data.action;
                delete data.click;
                delete data.change;
                delete data.focus;
                delete data.blur;
                
                delete data.value;
                delete data.val;
                delete data.rel;
                params.push(data);
                
                return resolveAction(name, params, $this, event);
            };
            
            app.$body.on('click', '[data-action]:not(form), [data-click]:not(form)', function (event) {
                bindEvent.call(this, 'click', event);
            });
            
            app.$body.on('focusin', '[data-focus]:not(input):not(textarea):not(select)', function (event) {
                bindEvent.call(this, 'focus', event);
            });
            
            app.$body.on('focusout', '[data-blur]:not(input):not(textarea):not(select)', function (event) {
                bindEvent.call(this, 'blur', event);
            });
            
            app.$body.find('input[data-change], textarea[data-change], select[data-change]').on('change paste', function (event) {
                bindEvent.call(this, 'change', event);
            });
            
            app.$body.find('input[data-focus], textarea[data-focus], select[data-focus]').on('focus', function (event) {
                bindEvent.call(this, 'focus', event);
            });
            
            app.$body.find('input[data-blur], textarea[data-blur], select[data-blur]').on('blur', function (event) {
                bindEvent.call(this, 'blur', event);
            });
        };
    }
    
    app.ActionResolver = new ActionResolver();
})(window, jQuery, Argument, app);
