(function (window, $, Argument, app) {
    var Array = window.Array;
    var document = window.document;
    var clearTimeout = window.clearTimeout;
    var setTimeout = window.setTimeout;
    var google = window.google;
    
    if (typeof google === 'undefined') {
        return;
    }
    
    function Map() {
        var _this = this;
        var maps = {};
        
        var markerColor = {
            blur: '#eee439',
            focus: '#f1e95d'
        };
        
        this.Symbols = {
            PIN: function(fillColor, fillOpacity, scale, strokeWeight, strokeColor) {
                return {
                    path: "M25 0c-8.284 0-15 6.656-15 14.866 0 8.211 15 35.135 15 35.135s15-26.924 15-35.135c0-8.21-6.716-14.866-15-14.866zm-.049 19.312c-2.557 0-4.629-2.055-4.629-4.588 0-2.535 2.072-4.589 4.629-4.589 2.559 0 4.631 2.054 4.631 4.589 0 2.533-2.072 4.588-4.631 4.588z",
                    anchor: new google.maps.Point(25, 50),
                    fillColor: fillColor || '#000',
                    fillOpacity: fillOpacity || 1,
                    scale: scale || 1,
                    strokeWeight: strokeWeight || 0,
                    strokeColor: strokeColor || '#000'
                };
            }
        };
        
        this.bind = function () {
            if (window.maps) {
                var maps = window.maps;
                
                for (var mapId in maps) {
                    var center = {
                        center: new google.maps.LatLng(
                            maps[mapId].center.lat,
                            maps[mapId].center.lng
                        ),
                        zoom: maps[mapId].zoom
                    };
                    
                    app.Map.init(mapId, center, window.mapStyle || []);
                    
                    google.maps.event.addDomListener(window, 'load', app.Map.create);
                }
                
                $(window).resize($.debounce(100, _this.centerAll));
            }
        };
        
        this.init = function (id, center, styles, marker) {
            var map = {};
            var options = center;
            
            if (typeof styles !== 'undefined') {
                options.styles = styles;
            }
            
            options.scrollwheel = false;
            options.gestureHandling = 'greedy';
            options.backgroundColor = '#2c5a71';
            
            // Controls
            options.mapTypeControl = false;
            options.fullscreenControl = false;
            options.streetViewControlOptions = {
                position: google.maps.ControlPosition.TOP_LEFT
            };
            options.zoomControlOptions = {
                position: google.maps.ControlPosition.TOP_LEFT
            };
            
            map.id = id;
            map.el = document.getElementById(id);
            map.created = false;
            map.options = options;
            map.marker = marker;
            
            maps[id] = map;
        };
        
        this.getMap = function (id) {
            return maps[id];
        };
        
        var createMarker = function(map) {
            if (typeof map.marker !== 'undefined') {
                var opacity, animation, icon, marker;
                
                opacity = 0;
                animation = google.maps.Animation.DROP;
                icon = new app.Map.Symbols.PIN(markerColor.blur, .9, .5);
                
                marker = new google.maps.Marker({
                    position: map.marker,
                    animation: animation,
                    map: map,
                    opacity: opacity,
                    icon: icon
                });
                
                marker = $.extend(marker, map.marker);
                
                map.marker = marker;
            }
        };
        
        this.create = function () {
            var tilesLoaded = function () {
                if (typeof this.marker !== 'undefined') {
                    _this.setMarkerVisibility(this.marker, 1);
                }
                
                this.created = true;
            };
            
            var enableScrollwheel = function () {
                this.setOptions({scrollwheel: true});
            };
            
            var enableScrollwheelTimeout = null;
            
            var delayedEnableScrollwheel = function () {
                var _this = this;
                
                enableScrollwheelTimeout = setTimeout(function () {
                    enableScrollwheel.call(_this);
                }, 1000);
            };
            
            var disableScrollwheel = function () {
                if (enableScrollwheelTimeout) {
                    clearTimeout(enableScrollwheelTimeout);
                }
                
                this.setOptions({scrollwheel: false});
            };
            
            for (var id in maps) {
                var map = maps[id];
                
                
                if (map.created) {
                    continue;
                }
                
                map = $.extend(new google.maps.Map(map.el, map.options), map);
                
                createMarker(map);
                google.maps.event.addListenerOnce(map, 'tilesloaded', tilesLoaded);
                
                google.maps.event.addListener(map, 'click', enableScrollwheel);
                google.maps.event.addListener(map, 'dragstart', enableScrollwheel);
                google.maps.event.addListener(map, 'rightclick', enableScrollwheel);
                
                google.maps.event.addListener(map, 'mouseover', delayedEnableScrollwheel);
                google.maps.event.addListener(map, 'mouseout', disableScrollwheel);
                
                maps[id] = map;
            }
        };
        
        this.setMarkerVisibility = function(marker, opacity, duration) {
            duration = Argument.get(duration, app.ANIMATION_DURATION);
            
            var d = $.Deferred();
            
            if (marker.getOpacity() === opacity) {
                return d.resolve().promise();
            }
            
            if (opacity === 0) {
                $({prop: 1}).animate({prop: 0}, {
                    duration: duration,
                    step: function(opacity) {
                        marker.setOpacity(opacity);
                    }
                }).promise().done(function() {
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                    
                    d.resolve();
                });
            } else {
                marker.setAnimation(google.maps.Animation.BOUNCE);
                marker.setAnimation(google.maps.Animation.DROP);
                marker.setOpacity(1);
                
                d.resolve();
            }
            
            return d.promise();
        };
        
        this.setZoom = function (mapId, zoom) {
            var map = this.getMap(mapId);
            
            map.setZoom(zoom);
            _this.center(mapId);
        };
        
        this.center = function (mapId) {
            var map = this.getMap(mapId);
            
            map.setCenter(map.options.center);
        };
        
        this.centerAll = function () {
            for (var id in maps) {
                _this.center(id);
            }
        };
    }
    
    app.registerModule('Map', new Map());
})(window, jQuery, Argument, app);
