(function (window, $, RecurringTimer, app) {
    var delay = window.delay,
        parseFloat = window.parseFloat;
    
    function Slider() {
        var _this = this;
        
        var getSlides = function (slider) {
            if (typeof slider === 'string') {
                return $('#' + slider + ' .slider\\.slides');
            }
            
            return slider.find('.slider\\.slides');
        };
        
        this.init = function ($slider) {
            var $slides = getSlides($slider);
            var type = $slider.data('type');
            
            var options = {
                autoplay: true,
                infinite: true,
                pauseOnHover: true,
                arrows: false,
                speed: app.ANIMATION_DURATION,
                autoplaySpeed: 5000
            };
            
            if (type == 'case-study' || type == 'quote' || type == 'client-quote') {
                options.speed = app.ANIMATION_DURATION * 1.5;
                options.autoplaySpeed = 2500;
            }
            
            if (type == 'logo') {
                options.autoplaySpeed = 2500;
                options.slidesToShow = 5;
                options.arrows = true;
                options.prevArrow = '.slider\\.control.\\--prev';
                options.nextArrow = '.slider\\.control.\\--next';
                options.responsive = [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ];
            }
            
            $slides.slick(options);
        };
        
        this.goto = function (slider, $dot, data) {
            getSlides(slider).slick('slickGoTo', data.slide);
        };
        
        this.next = function (slider) {
            getSlides(slider).slick('slickNext');
        };
        
        this.prev = function (slider) {
            getSlides(slider).slick('slickPrev');
        };
    }
    
    app.registerModule('Slider', new Slider());
})(window, jQuery, RecurringTimer, app);

