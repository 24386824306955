(function (window, $, app) {
    app.init();
    
    app.$doc = $(window.document);
    app.$body = $('body');
    
    app.ANIMATION_DURATION = 300;
    app.BOOTSTRAP_BREAKS = {
        sm: 768,
        md: 992,
        lg: 1200
    };
    app.NAV_BREAKPOINT = 1208;

    app.bind();
    app.ActionResolver.bindActions();
    
    var mapsInit = function () {
        if (window.maps) {
            var maps = window.maps;
            var google = window.google;
            
            if (! google) {
                return;
            }
            
            var toLatLng = function (marker) {
                marker = new google.maps.LatLng(marker.lat, marker.lng);
                
                return marker;
            };
            
            for (var mapId in maps) {
                var center = {
                    center: new google.maps.LatLng(
                        maps[mapId].center.lat,
                        maps[mapId].center.lng
                    ),
                    zoom: maps[mapId].zoom
                };
                
                var marker = toLatLng(maps[mapId].marker);
                
                app.Map.init(mapId, center, window.mapStyle, marker);
                
                google.maps.event.addDomListener(window, 'load', app.Map.create);
            }
        }
    };
    
    mapsInit();
})(window, jQuery, app);
