var Argument = Argument || {};

(function (app) {
    app.get = function (arg, d) {
        if (typeof arg === 'undefined') {
            arg = d;
        }
        
        return arg;
    };
})(Argument);
