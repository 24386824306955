(function (window, $, Argument, app) {
    var Math = window.Math;
    var RegExp = window.RegExp;
    var document = window.document;
    var history = window.history;
    var location = window.location;
    var parseInt = window.parseInt;
    var parseFloat = window.parseFloat;

    function Scroll() {
        var _this = this;

        var getFirstSection = function () {
            return $('[id^=section-]:first');
        };

        var getNextSection = function () {
            var $nav = $('#nav-main');
            var $wpAdmin = $('#wpadminbar');

            var navHeight = $nav.height();
            var adminHeight = $wpAdmin.length ? $wpAdmin.outerHeight() : 0;

            return $('[id^=section-]:in-viewport(' + (navHeight + adminHeight + 64) + ')').next();
        };

        var getPreviousSection = function () {
            var $nav = $('#nav-main');
            var $wpAdmin = $('#wpadminbar');

            var navHeight = $nav.height();
            var adminHeight = $wpAdmin.length ? $wpAdmin.outerHeight() : 0;

            return $('[id^=section-]:in-viewport(' + (navHeight + adminHeight + 64) + ')').prev();
        };

        var getLastSection = function () {
            return $('[id^=section-]:last');
        };

        this.section = function (to) {
            var $to;
            var $first = getFirstSection();

            switch (to) {
                case 'first':
                case 'first-section':
                    $to = $first;
                    break;
                case 'next':
                case 'next-section':
                    $to = getNextSection();
                    break;
                case 'prev':
                case 'prev-section':
                case 'previous':
                case 'previous-section':
                    $to = getPreviousSection();
                    break;
                case 'last':
                case 'last-section':
                    $to = getLastSection();
                    break;
                default:
                    $to = $('#section-' + to);
                    break;
            }

            if ($to.length === 0) {
                return;
            }

            var id = $to.attr('id').replace('section-', '');
            var $nav = $('#nav-main');
            var $wpAdmin = $('#wpadminbar');

            var navHeight = $nav.outerHeight();
            var adminHeight = $wpAdmin.length ? $wpAdmin.outerHeight() : 0;

            var top = $to.offset().top;
            var padding = parseInt($to.css('padding-top'));
            var border = parseInt($to.css('border-top-width'));
            var margin = parseInt($to.css('margin-top'));

            var scrollTo = Math.ceil(top + border - margin - navHeight - adminHeight);
            var maxScrollPos = app.$doc.height() - $(window).height();

            scrollTo = Math.max(Math.min(scrollTo, maxScrollPos), 0);

            var distance = window.Math.abs($(window).scrollTop() - scrollTo);
            var speed = 1400 / 1000; // in px/ms
            var duration = Math.min(distance / speed, 3000);

            $("html, body").animate({scrollTop: scrollTo}, duration, "easeInOutExpo");

            if (history) {
                var hash = '';
                var section = '';
                var targetSectionRegex = '';
                
                if ($first.attr('id').replace('section-', '') !== id) {
                    hash = '#/' + id;
                    section = 'section/' + id;
                    targetSectionRegex = new RegExp('\/section\/' + id + '(?:\/|$)');
                } else {
                    targetSectionRegex = new RegExp('\/section\/' + id + '(?:\/|$)');
                }
                
                if (history.pushState) {
                    // No need to change state if the current url already links to the correct section
                    if (! location.href.match(targetSectionRegex)) {
                        var sectionRegex = '';
                        
                        if (location.href.match(/\/section\/[A-z0-9_-]+(\/|$)/)) {
                            sectionRegex = /(\/)section\/[A-z0-9_-]+(\/|$)/;
                        } else {
                            sectionRegex = /()(#|\?|\/$|$)/;
                        }
                        
                        history.pushState(null, null, location.href.replace(sectionRegex, '$1' + section + '$2').replace(/([^\/])#\//, '$1/#/'));
                    }
                } else {
                    location.hash = hash;
                }
            }

            return false;
        };

        var getViewState = function ($container, $el, opts) {
            var height;

            if (! $el.get(0).isVisible()) {
                return 'outViewport';
            }

            switch (opts.mode) {
                case 'inner':
                    height = $el.innerHeight();
                    break;
                case 'outer':
                    height = $el.outerHeight();
                    break;
                case 'full':
                    height = $el.outerHeight(true);
                    break;
                default:
                    height = $el.height();
                    break;
            }

            var el = {
                top: $el.offset().top,
                height: height
            };

            var vp = {
                top: $container.scrollTop() + opts.offset.top,
                height: $container.height() - opts.offset.top - opts.offset.bottom
            };

            var pxInView = Math.max(0, Math.min(el.top + el.height, vp.top + vp.height) - Math.max(el.top, vp.top));
            var inView;

            if (opts.amount === 'full') {
                inView = pxInView == el.height;
            } else if (opts.amount === 'any') {
                inView = pxInView > 0;
            } else if (opts.amount === parseInt(opts.amount)) {
                inView = pxInView >= opts.amount;
            } else if ((typeof opts.amount === 'string' && opts.amount.match(/^\d+%$/)) || (typeof opts.amount === 'number' && opts.amount >= 0 && opts.amount <= 1)) {
                if (typeof opts.amount === 'string') {
                    opts.amount = parseFloat(opts.amount) / 100;
                }

                inView = pxInView >= el.height * opts.amount;
            }

            return inView ? 'inViewport' : 'outViewport';
        };

        var fireSpyEvent = function ($container, $items, opts) {
            var options = {
                offset: {
                    top: typeof opts.offset === 'function' ? opts.offset() : opts.offset,
                    bottom: 0
                },
                mode: 'outer',
                amount: 'any'
            };

            var stateChanged = false;
            var changed = [];

            $items.each(function () {
                var $el = $(this);
                var knownState = $el.data('scroll-spy-state');
                var currentState = getViewState($container, $el, options);

                if (currentState !== knownState) {
                    stateChanged = true;
                    $el.data('scroll-spy-state', currentState);
                }

                changed.push({
                    $el: $el,
                    state: currentState
                });
            });

            if (stateChanged && changed.length) {
                opts.onChange(changed);
            }
        };

        this.spy = function ($container, items, opts) {
            $container = typeof $container === 'string' ? $($container) : $container;

            var $items = items instanceof $ ? items : ($.isWindow($container.get(0)) ? $(items) : $container.find(items));

            fireSpyEvent($container, $items, opts);

            $container.scroll(function () {
                fireSpyEvent($container, $items, opts);
            });

            $(window).resize(function () {
                fireSpyEvent($container, $items, opts);
            });
        };

        var fireViewEvent = function ($elements, opts) {
            $elements.each(function () {
                var $el = $(this);
                var knownState = $el.data('scroll-inview-state');
                var currentState = getViewState($(window), $el, opts);

                if (currentState !== knownState) {
                    $el.data('scroll-inview-state', currentState);

                    if (currentState == 'inViewport') {
                        opts.onViewportEnter.apply($el.get(0));
                    } else {
                        opts.onViewportLeave.apply($el.get(0));
                    }
                }
            });
        };

        this.inView = function ($elements, opts) {
            opts = Argument.get(opts, {});

            var defaults = {
                amount: 'full',
                mode: 'content',
                offset: {
                    top: 0,
                    bottom: 0
                },
                onViewportEnter: function () {},
                onViewportLeave: function () {},
            };

            var options = $.extend(true, {}, defaults, opts);

            fireViewEvent($elements, options);

            $(window).on('scroll resize Tab:visibilityChange', function () {
                fireViewEvent($elements, options);
            });
        };
    }

    app.registerModule('Scroll', new Scroll());
})(window, jQuery, Argument, app);
