(function (window, $, app) {
    var Math = window.Math;
    var delay = window.delay;
    
    function Nav() {
        var _this = this;
        
        this.bind = function () {
            var $nav = $('.nav');
            var $before = $('.nav\\.bg\\.before');
            var $firstSection = $('.section:first');
            
            $before.height($firstSection.outerHeight());
            
            $nav.on('click', '.nav\\.section.\\--desktop .nav\\.item', function () {
                if (window.innerWidth < app.NAV_BREAKPOINT) {
                    app.exec('Nav.close');
                }
            });
            
            $(window).resize(function () {
                $before.height($firstSection.outerHeight());
                
                if (window.innerWidth > app.NAV_BREAKPOINT) {
                    app.exec('Nav.close');
                }
            });
        };
        
        this.open = function () {
            var $nav = $('.nav');
            var $bg = $('.nav\\.bg');
            var $collapse = $('.nav .nav\\.section.\\--desktop');
            var $items = $collapse.find('.nav\\.item');
            var height = Math.min(($items.outerHeight() * ($items.length - 1)), parseInt($collapse.css('max-height')));
            var navHeight = $nav.height();
            
            app.$body.addClass('@nav-open');
            $collapse.css({height: height});
            $bg.css({height: height + navHeight});
        };
        
        this.close = function () {
            app.$body.removeClass('@nav-open');
            $('.nav .nav\\.section.\\--desktop').css({height: ''});
            $('.nav\\.bg').css({height: ''});
        };
        
        this.toggle = function () {
            if (app.$body.hasClass('@nav-open')) {
                this.close();
            } else {
                this.open();
            }
        };
    }
    
    app.registerModule('Nav', new Nav());
})(window, jQuery, app);
