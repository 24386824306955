var Console = Console || {};

(function (window, app) {
    var Array = window.Array;
    var Date = window.Date;
    var c = window.console || false;
    var fn = {};
    
    fn.out = function (method, v) {
        var now = new Date();
        
        if (c !== false) {
            if (c[method].call) {
                c[method].call(c, '[' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + '.' + now.getMilliseconds() + ']', v);
            } else {
                c[method]('[' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + '.' + now.getMilliseconds() + ']', v);
            }
        }
    };
    
    app.error = function (v) {
        if (arguments.length > 1) {
            for (var i = 0; i < arguments.length; i++) {
                app.error(arguments[i]);
            }
            
            return;
        }
        
        fn.out('error', v);
    };
    
    app.warn = function (v) {
        if (arguments.length > 1) {
            for (var i = 0; i < arguments.length; i++) {
                app.warn(arguments[i]);
            }
            
            return;
        }
        
        fn.out('warn', v);
    };
    
    app.info = function (v) {
        if (arguments.length > 1) {
            for (var i = 0; i < arguments.length; i++) {
                app.log(arguments[i]);
            }
            
            return;
        }
        
        fn.out('info', v);
    };
    
    app.log = function (v) {
        if (arguments.length > 1) {
            for (var i = 0; i < arguments.length; i++) {
                app.log(arguments[i]);
            }
            
            return;
        }
        
        fn.out('log', v);
    };
})(window, Console);
