if ('app' in window) {
    window.app = {
        _app: window.app,
        init: function () {
            window[this.NAME] = this;
            window.app = this._app;
            
            delete this._app;
        }
    };
} else {
    window.app = {
        init: function () {
            window[this.NAME] = this;
            
            delete window.app;
        }
    };
}

window.app.NAME = 'ThreeSixty';

(function (window, $, Odometer, Argument, app) {
    var parseFloat = window.parseFloat;

    app.modules = {};   

    app.supports = function (feature) {
        return window.Modernizr[feature];
    };

    app.rejects = function (feature) {
        return ! window.Modernizr[feature];
    };

    app.exec = function (name) {
        app.ActionResolver.exec.apply(app.ActionResolver, arguments);
    };

    app.registerAction = function (name, action) {
        app.ActionResolver.registerAction(name, action);
    };

    app.registerModule = function (name, module) {
        app[name] = app.modules[name] = module;
    };

    app.bind = function () {
        /**
         * Bind any modules that require it.
         */
        for (var moduleName in app.modules) {
            if (app.modules.hasOwnProperty(moduleName) && app.modules[moduleName].hasOwnProperty('bind') && typeof app.modules[moduleName].bind == 'function') {
                app.modules[moduleName].bind();
            }
        }
        
        app.$doc.ready(function () {
            var Modernizr = window.Modernizr;
            
            // Throw Modernizr console warnings
            for (var prop in Modernizr) {
                if (Modernizr.hasOwnProperty(prop)) {
                    if (Modernizr[prop] === false) {
                        window.Console.warn('Your browser does not support "' + prop + '", please consider upgrading to a modern browser like Google Chrome <https://www.google.com/chrome/>');
                    }
                }
            }
            
            // Nav
            var scrollToCurrent = function () {
                if (window.location.hash && window.location.hash.match(/^#\//)) {
                    var section = window.location.hash.replace(/^#\//, '');
                    
                    app.Scroll.section(section, false);
                }
            };
            
            scrollToCurrent();
            
            if (window.history) {
                $(window).on('popstate', function (e) {
                    if ('scrollRestoration' in window.history) {
                        window.history.scrollRestoration = 'manual';
                    }
                    
                    e.preventDefault();
                    scrollToCurrent();
                    
                    if (! window.location.hash) {
                        app.Scroll.section('first', false);
                    }
                });
            } else {
                $('a[href*="/#/"]').click(scrollToCurrent);
            }
            
            // Figures
            $('.figure\\.figure').each(function () {
                var $figure = $(this);
                
                var od = new Odometer({
                    el: $figure.get(0),
                    value: 0,
                    theme: 'minimal',
                    duration: 2000
                });
                
                $figure.data('figure-odometer', od);
            });
            
            app.Scroll.inView($('[data-figure]'), {
                onViewportEnter: function () {
                    var $this = $(this);
                    var $figure = $this.find('.figure\\.figure');
                    var od = $figure.data('figure-odometer');
                    
                    od.update($this.data('figure'));
                }
            });
            
            // Sliders
            $('.slider').on('init', function () {
                $(this).addClass('--ready');
            }).each(function () {
                app.Slider.init($(this));
            });
        });
    };
})(window, jQuery, Odometer, Argument, app);
